"use client"

import type { User } from "@prisma/client"
import { createContext, useContext } from "react"

export const UserContext = createContext<User | null>(null)

export type UserContextProps = {
  children: React.ReactNode
}

export const useUser = () => {
  const result = useContext(UserContext)
  return result
}
