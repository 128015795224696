"use client"

import type { Session, User } from "@prisma/client"
import { createContext, useContext } from "react"

export type SupportedLoginProvider = "google"
export type SuccessCallback = (payload: { user: User; session: Session }) => Promise<void> | void
export type ISessionContext = {
  session: Session | null
  startLogin: (provider: SupportedLoginProvider, onSuccess?: SuccessCallback) => Promise<void>
  getLoginUrl: (options: {
    provider: SupportedLoginProvider
    redirectTo?: string
    target: "_blank" | "_popup"
  }) => string
  logout: () => Promise<void>
}

export const SessionContext = createContext<ISessionContext>({
  session: null,
  startLogin: async () => { },
  getLoginUrl: () => "",
  logout: async () => { },
})

export const useSession = () => {
  const result = useContext(SessionContext)
  return result
}
