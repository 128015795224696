import { PublicJobSearchResult, StoreSearchResult } from "@/database-types"
import { MapState } from "@/types"

export const getOptionsFromLabels = (labels: string[]): { label: string; value: string }[] => {
  return labels.map((label) => ({
    value: label.toLowerCase().replace(/ /g, "-"),
    label,
  }))
}

export const groupJobSearchResultsByStore = (results: PublicJobSearchResult[]): StoreSearchResult[] => {
  const grouped = results.reduce(
    (acc, row) => {
      if (acc[row.store_id]) {
        acc[row.store_id].push(row)
      } else {
        acc[row.store_id] = [row]
      }
      return acc
    },
    {} as Record<string, PublicJobSearchResult[]>
  )

  return Object.values(grouped).map((jobs) => {
    const lat = jobs[0].store_lat
    const lng = jobs[0].store_lng
    const store = jobs[0].Store
    const employer = jobs[0].Employer

    const flatJobs = jobs.map((job) => ({
      ...job,
      Store: undefined,
      Employer: undefined,
    }))

    return {
      ...store,
      lat,
      lng,
      employer,
      jobs: flatJobs,
      job_count: jobs.length,
    }
  })
}

export const getSearchParamsForMapState = (searchParams: URLSearchParams, state: Partial<MapState>) => {
  const queryString = new URLSearchParams(Array.from(searchParams.entries()))

  const {
    storeId,
    lat,
    lng,
    zoom,
    payMin,
    jobCategories,
    categories,
    occupations,
    employers,
    excludeEmployers,
    search,
    job,
    sortBy,
    showHidden,
    allJobs,
    verified,
    floor,
    feeds,
  } = state

  if (storeId) {
    queryString.set("store", storeId)
  } else {
    queryString.delete("store")
  }

  if (lat) queryString.set("lat", lat.toString())
  if (lng) queryString.set("lng", lng.toString())
  if (zoom) queryString.set("zoom", zoom.toString())

  if (payMin) {
    queryString.set("pay_min", payMin.toString())
  } else {
    queryString.delete("pay_min")
  }

  if (categories?.length) {
    queryString.set("categories", categories.join(","))
  } else {
    queryString.delete("categories")
  }

  if (occupations?.length) {
    queryString.set("occupations", occupations.join(","))
  } else {
    queryString.delete("occupations")
  }

  if (jobCategories?.length) {
    queryString.set("job_categories", jobCategories.join(","))
  } else {
    queryString.delete("job_categories")
  }

  if (employers?.length) {
    queryString.set("employers", employers.join(","))
  } else {
    queryString.delete("employers")
  }

  if (excludeEmployers?.length) {
    queryString.set("exclude_employers", excludeEmployers.join(","))
  } else {
    queryString.delete("exclude_employers")
  }

  if (search?.length) {
    queryString.set("search", search.join(","))
  } else {
    queryString.delete("search")
  }

  if (job) {
    queryString.set("job", job)
  } else {
    queryString.delete("job")
  }

  if (sortBy && sortBy !== "score_v2") {
    queryString.set("sort_by", sortBy)
  } else if (sortBy === "score_v2" || !sortBy) {
    queryString.delete("sort_by")
  }

  if (showHidden) {
    queryString.set("show_hidden", "true")
  } else {
    queryString.delete("show_hidden")
  }

  if (allJobs) {
    queryString.set("all_jobs", "true")
  } else {
    queryString.delete("all_jobs")
  }

  if (verified) {
    queryString.set("verified", "true")
  } else {
    queryString.delete("verified")
  }

  if (floor) {
    queryString.set("floor", floor.toString())
  } else {
    queryString.delete("floor")
  }

  if (feeds?.length) {
    queryString.set("feeds", feeds.join(","))
  } else {
    queryString.delete("feeds")
  }

  return queryString
}

export const workmapsStateToApiSearchParams = (
  state: Partial<MapState>,
  extraParams?: Record<string, string | number | boolean>
) => {
  const queryString = new URLSearchParams()

  extraParams && Object.entries(extraParams).forEach(([key, value]) => queryString.set(key, value.toString()))

  const {
    payMin,
    categories,
    occupations,
    employers,
    excludeEmployers,
    search,
    sortBy,
    showHidden,
    allJobs,
    verified,
    floor,
    jobCategories,
    feeds,
    dynamicFloor,
    dynamicFloorLimit,
  } = state
  if (payMin) {
    queryString.set("soft_min_pay", payMin.toString())
  }

  if (categories?.length) {
    queryString.set("categories", categories.join(","))
  }

  if (occupations?.length) {
    queryString.set("occupations", occupations.join(","))
  }

  if (jobCategories?.length) {
    queryString.set("job_categories", jobCategories.join(","))
  }

  if (employers?.length) {
    queryString.set("employers", employers.join(","))
  }

  if (excludeEmployers?.length) {
    queryString.set("exclude_employers", excludeEmployers.join(","))
  }

  if (search?.length) {
    queryString.set("search", search.join(","))
  }

  if (sortBy && sortBy !== "score_v2") {
    queryString.set("sort_by", sortBy)
  }

  if (typeof showHidden === "boolean" && showHidden) {
    queryString.set("show_hidden", "true")
  }

  if (typeof allJobs === "boolean" && !allJobs) {
    queryString.set("all_jobs", "false")
  }

  if (typeof verified === "boolean" && !verified) {
    queryString.set("verified", "false")
  }

  if (floor) {
    queryString.set("floor", floor.toString())
  }

  if (feeds?.length) {
    queryString.set("feeds", feeds.join(","))
  }

  if (dynamicFloor) {
    queryString.set("dynamic_floor", "true")
  }

  if (dynamicFloorLimit && dynamicFloorLimit > 0) {
    queryString.set("dynamic_floor_limit", dynamicFloorLimit.toString())
  }

  return queryString
}

export const toTitleCase = (value: string) => {
  return value
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}
