"use client"

import type { MapFunctions, ReactPropsOnlyChildren } from "@/types"
import { createContext, useContext, useState, useCallback, useEffect, SetStateAction, Dispatch } from "react"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useIsMedium } from "@/lib/frontend/util"

const MapContext = createContext<{
  map: MapFunctions | undefined
  setMap: (map: MapFunctions) => void
  mapVisible: boolean
  setMapVisible: Dispatch<SetStateAction<boolean>>
}>({
  map: {
    goTo() {
      throw new Error("Not implemented.")
    },
  },
  setMap: () => {
    // no-op
  },
  mapVisible: false,
  setMapVisible: () => {
    // no-op
  },
})

export type MapProviderProps = {
  children: React.ReactNode
}

export const MapProvider: React.FC<ReactPropsOnlyChildren> = ({ children }) => {
  // While the map is loading, some other part of the application may instruct
  // the map to go somewhere. This will save the latest call of `goTo` and
  // navigate to it once the map is ready.
  const [, setEnqueuedGoTo] = useState<Parameters<MapFunctions["goTo"]>[0] | null>(null)
  const goTo = useCallback((position: Parameters<MapFunctions["goTo"]>[0]) => {
    setEnqueuedGoTo(position)
  }, [])

  const [map, _setMap] = useState<MapFunctions>({ goTo, map: undefined })
  const setMap = useCallback((updatedMap: MapFunctions) => {
    _setMap(updatedMap)

    if (!updatedMap) {
      return
    }

    setEnqueuedGoTo((enqueuedGoTo) => {
      if (enqueuedGoTo) {
        updatedMap.goTo(enqueuedGoTo)
        return null
      }

      return enqueuedGoTo
    })
  }, [])

  // This flag is going to 'list' for most traffic, but we have it be map on
  // organic direct traffic as they could be investors and we want them to see
  // the map by default.
  const [mapVisible, setMapVisible] = useState(false)
  const [hasToggledMapVisible, setHasToggledMapVisible] = useState(false)

  useEffect(() => {
    if (!hasToggledMapVisible) {
      setMapVisible(false)
      setHasToggledMapVisible(true)
    }
  }, [hasToggledMapVisible])

  // The map is always visible on non-mobile devices. We must default this to true so that mobile can default to list
  // view. Desktop is already lazy loading the map so it has a render cycle to swap this, which mobile does not.
  const isMedium = useIsMedium(true)
  useEffect(() => {
    if (!isMedium) {
      setMapVisible(true)
    }
  }, [isMedium])

  // We need to attach to each event whether or not the map is visible
  const analytics = useAnalytics()
  useEffect(() => {
    analytics.register({
      name: "Workmaps.MapVisbility",
      load: async () => true,
      isLoaded: () => true,
      version: "1.0.0",
      type: "before",
      track: (ctx) => {
        setMapVisible((curr) => {
          ctx.updateEvent("properties.map_visible", curr)
          return curr
        })
        return ctx
      },
    })
  }, [analytics])

  return <MapContext.Provider value={{ map, setMap, mapVisible, setMapVisible }}>{children}</MapContext.Provider>
}

export const useMap = () => {
  const result = useContext(MapContext)
  return result
}
