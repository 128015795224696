import { createContext, useContext } from "react"
import type { AnalyticsBrowser } from "@segment/analytics-next"

export const AnalyticsContext = createContext<AnalyticsBrowser>(null!)

export const useAnalytics = () => {
  const result = useContext(AnalyticsContext)
  if (!result) {
    throw new Error("Context used outside of its Provider!")
  }
  return result
}
