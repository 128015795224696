"use client"

import type { InitialLocationGeoData, MapState, SsrExposedExperiment, WorkmapsState } from "@/types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { createContext, useEffect, useReducer } from "react"
import { IJobsCatalogContent } from "./WorkmapsJobsCatalogProvider"
import { useSearchParams } from "next/navigation"
import { getSearchParamsForMapState } from "@/lib/shared/util"
import { useMap } from "@/components/MapProvider"
import { useIsMedium, replaceURL } from "@/lib/frontend/util"
import { useCurrentUrl } from "@/lib/frontend/hooks"
import { useStatsigClient } from "@statsig/react-bindings"

export type WorkmapsFiltersProps = React.PropsWithChildren<
  Omit<MapState, "stores" | "storesPromise" | "driftedFromDefaultState" | "onNextStoresUpdate">
> & {
  initialJobsCount?: number
  ssrExposedExperiments?: SsrExposedExperiment[]
  userGeo: InitialLocationGeoData
}

export const WorkmapsContext = createContext<WorkmapsState | undefined>(undefined)

/**
 * This is a provider that provides functions to update the job search filters for the application. Updating the filters
 * may also update the URL so that the filtered results can be linked to.
 */
export const WorkmapsFiltersProvider: React.FC<WorkmapsFiltersProps> = ({
  children,
  initialJobsCount,
  ssrExposedExperiments,
  ...initialFilters
}) => {
  const queryClient = useQueryClient()

  const initialState: MapState = {
    ...initialFilters,
    lat: initialFilters.lat,
    lng: initialFilters.lng,
    driftedFromDefaultState: !initialJobsCount || initialJobsCount === 0,
    payMin: initialFilters.payMin ?? initialFilters.softMinPay,
  }

  const reducer = (state: MapState, newState: Partial<MapState>): MapState => {
    const derivedState: Partial<MapState> = {
      driftedFromDefaultState: state.driftedFromDefaultState,
    }

    // If the user landed on the site with a store ID and moves the map/filters, unselect it so the sidebar does not
    // scroll to it.
    if (state.storeId === initialFilters.storeId) {
      derivedState.storeId = undefined
    }

    // We only want to refresh the SSR map if the user moves it or applies a filter, not if they click on a job/store,
    // as that will refresh the list and possibly remove the target from the list.
    if (!derivedState.driftedFromDefaultState && !("job" in newState) && !("store" in newState)) {
      derivedState.driftedFromDefaultState = true
    }

    // If we trigger a job click, we need to set the storeId to the jobs's store, if it was not provided
    if (newState.job && !newState.storeId) {
      const loadedJobs = queryClient.getQueryData<IJobsCatalogContent>(["jobCatalog"])?.jobs ?? []
      derivedState.storeId = loadedJobs.find((job) => job.id === newState.job)?.store_id
    }

    // When the user searches, we should widen the search target. This is needed when the user is on the homepage, which
    // has `-skilled` and `job_hidden = false`, and they search for nursing jobs, which are counter acted by showHidden.
    if (newState.search?.length) {
      derivedState.showHidden = true
      derivedState.allJobs = true
    }

    return {
      ...state,
      ...derivedState,
      ...newState,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // #region URL updating
  // As the user moves around the map, in most cases, we want to update the URL to match a subset of the MapState so it
  // can be loaded later.
  const searchParams = useSearchParams()
  const { mapVisible } = useMap()
  const isMedium = useIsMedium()
  const currentUrl = useCurrentUrl({ includeSearch: false })
  useEffect(() => {
    // On first load, we need to keep the URL parameters the same until the user moves the map, clicks a job, searches,
    // etc. If we do this immediately, Google thinks it is a redirect and will penalize us.
    //
    // We should update the URL if the user has selected a job or store though.
    if (!state.driftedFromDefaultState && !state.storeId && !state.job) {
      return
    }

    const oldSearchParams = new URLSearchParams(searchParams)
    const newSearchParams = getSearchParamsForMapState(
      searchParams,
      // On the mobile map view, users move the map a lot. So much so that we run into a hard limit on how often
      // `history.replaceState` can be called. To avoid this, we only store the lat/lng in the URL when the map is
      // moved. When the map is dismissed and the list view comes back, the URL will update to the new lat/lng. We also
      // want to update the URL if a store is selected so that sharing the job by URL works.
      mapVisible && isMedium && !state.storeId ? { ...state, lat: undefined, lng: undefined } : state
    )

    if (oldSearchParams.toString() !== newSearchParams.toString()) {
      replaceURL(`${currentUrl}?${newSearchParams.toString()}`)
    }
  }, [currentUrl, searchParams, state, mapVisible, isMedium])

  // #region Statsig
  // The user may have been exposed to experiments during server rendering. If they have, we need to log that exposure
  // on the frontend so that external systems are aware of it. Specifically, this allows us to see users in experiments
  // on Hotjar.
  //
  // Statsig logs exposures with the `.get` call.
  const statsig = useStatsigClient()
  useQuery({
    queryKey: ["statsig", "ssrExposedExperiments", ssrExposedExperiments] as const,
    initialData: false,
    queryFn: async ({ queryKey: [, , ssrExposedExperiments] }) => {
      if (!ssrExposedExperiments) {
        return true
      }

      ssrExposedExperiments.forEach((experiment) => {
        if (experiment.type === "layer") {
          const layer = statsig.getLayer(experiment.name)
          experiment.keys.forEach((key) => {
            layer.get(key)
          })
        } else if (experiment.type === "experiment") {
          const exp = statsig.getExperiment(experiment.name)
          experiment.keys.forEach((key) => {
            exp.get(key)
          })
        }
      })

      return true
    },
  })

  return <WorkmapsContext.Provider value={{ state, dispatch }}>{children}</WorkmapsContext.Provider>
}
