import React, { createContext, useContext, useReducer, useCallback, type ReactNode, type ReactElement } from "react"

type PortalState = {
  [key: string]: ReactElement | null
}

type PortalAction = { type: "OPEN"; key: string; component: ReactElement } | { type: "CLOSE"; key: string }

const initialPortalState: PortalState = {}

const PortalContext = createContext<
  | {
      state: PortalState
      dispatch: React.Dispatch<PortalAction>
    }
  | undefined
>(undefined)

PortalContext.displayName = "PortalContext"

function portalReducer(state: PortalState, action: PortalAction): PortalState {
  switch (action.type) {
    case "OPEN":
      return { ...state, [action.key]: action.component }
    case "CLOSE":
      return { ...state, [action.key]: null }
    default:
      return state
  }
}

export function PortalProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(portalReducer, initialPortalState)

  return (
    <PortalContext.Provider value={{ state, dispatch }}>
      {children}
      {Object.values(state).map((component, index) =>
        component ? React.cloneElement(component, { key: index }) : null
      )}
    </PortalContext.Provider>
  )
}

export function usePortal(key: string) {
  const context = useContext(PortalContext) ?? {}
  // @ts-expect-error I cannot unpack and have the `!context` below with hooks in this component.
  const { state, dispatch } = context

  const open = useCallback(
    (component: ReactElement) => {
      dispatch({ type: "OPEN", key, component })
    },
    [key, dispatch]
  )

  const close = useCallback(() => {
    dispatch({ type: "CLOSE", key })
  }, [key, dispatch])

  if (!context) {
    throw new Error("usePortal must be used within a PortalProvider")
  }

  return {
    open,
    close,
    isOpen: !!state[key],
    component: state[key],
  }
}
